import '../style/App.scss';

export default class Validators {
    validateEmail(email){
        var emailError = false;

        if (email === '') {
            emailError = true;
        } else if (email !== '') {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            var emailValid = (reg.test(email) === true);

            emailError = emailValid ? false : true;
        }

        return emailError;
    }

    getAllData(){
        this.getGeneralData();
        this.getPageData();
        this.getCategories();
        this.getCodes();
    }

    getCodes(){
        fetch('https://exclusivehotelbookings-cms.tecdam.io/public/exclusivehotelbookings/items/codes?fields=*.*')
        .then(response => response.json())
        .then(data => {
            localStorage.setItem('codes', JSON.stringify(data.data));
        });
    }

    getGeneralData(){
        fetch('https://exclusivehotelbookings-cms.tecdam.io/public/exclusivehotelbookings/items/general?fields=*.*')
        .then(response => response.json())
        .then(data => {
            localStorage.setItem('generalData', JSON.stringify(data.data[0]));
        });
    }

    getPageData(){
        fetch('https://exclusivehotelbookings-cms.tecdam.io/public/exclusivehotelbookings/items/pages?fields=*.*')
        .then(response => response.json())
        .then(data => {
            var pages = data.data;
            localStorage.setItem('pagesData', JSON.stringify(pages));
        });
    }

    getCategories(){
        fetch('https://exclusivehotelbookings-cms.tecdam.io/public/exclusivehotelbookings/items/travel_categories?fields=*.*')
        .then(response => response.json())
        .then(data => {
            localStorage.setItem('catData', JSON.stringify(data.data));
        });
    }

    validateCode(code){
        var codes = JSON.parse(localStorage.getItem('codes'));
        if(codes !== null && codes !== undefined){
             for ( var i = 0; i < codes.length; i++ ) {
                 if(codes[i].code === code){
                    // if(!codes[i].status){
                        return true;
                    // } else {
                    //     return 'used';
                    // }
                 }
             }
        } else {
            return false;
        }
    }

    getEmailTemplates(){
        return  fetch('https://exclusivehotelbookings-cms.tecdam.io/public/exclusivehotelbookings/items/email_templates?fields=*.*');
    }

    sendEmail(emailTo, type){
        this.getEmailTemplates().then(response => response.json())
        .then(data => {
            let template = data.data.filter(obj => obj.form == type);
            if(template.length == 0) {
                return;
            }
            template = template[0];
            fetch('https://api.sendinblue.com/v3/smtp/email', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'api-key': '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'
                },
                body: JSON.stringify({
                    to: [{"email": emailTo}],
                    name: "Exclusive Hotel Bookings",
                    subject: template.subject,
                    htmlContent: template.content,
                    sender: { name: "Exclusive Hotel Bookings", email:"hola@tecdam.io" }
                })
            });
        });        
    }
}