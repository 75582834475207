import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landingspage from './landingspage.js';
import InvitePage from './invite.js';
import VipPage from './vip.js';
import LoginPage from './login.js';
import TextPage from './textpage.js';
import NoMatchPage from './fourofour.js';

class Navigation extends React.Component {

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/" component={Landingspage}/>
                    <Route exact path="/invite" component={InvitePage}/>
                    <Route exact path="/vip" component={VipPage}/>
                    <Route exact path="/login" component={LoginPage}/>
                    <Route exact path="/general-conditions" component={TextPage}/>
                    <Route exact path="/privacy-policy" component={TextPage}/>
                    <Route component={NoMatchPage} />
                </Switch>
            </Router>
        );
    }
}

export default Navigation;

