import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style/App.scss';

import Header from '../components/header';
import Footer from '../components/footer';
import InputField from '../components/inputfield';
import Button from '../components/button';
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import ArrowDownWhite from '../img/arrow-down.png';
import ArrowUpBlack from '../img/arrow-up.png';
import { ScrollTo } from "react-scroll-to";
import Validators from '../helpers/functions';
const validator = new Validators();
var parse = require('html-react-parser');

const fadeDuration = 10

class Onboarding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            general_data: null,
            page_data: null,
        };
    }
    componentDidUpdate(prevProps) {
        if (
          this.props.location.pathname !== prevProps.location.pathname
        ) {
            this.setState({
                general_data: null
            },() =>{
                validator.getAllData();
                this._showData();
                window.scrollTo(0, 0);
            });
            
        }
    }

      
    componentDidMount(){
        validator.getAllData();
        this._showData();
        window.scrollTo(0, 0);
    }

    _showData(){
        var generalData = JSON.parse(localStorage.getItem('generalData'));
        var that = this;

        if(generalData !== null && generalData !== undefined){  
            setTimeout(function(){
                that.setState({
                    general_data: generalData
                });
            }, 250);
        } else {
            setTimeout(function(){
                that._showData();
            }, 250);
        }
 
        var pagesData = JSON.parse(localStorage.getItem('pagesData'));
        if(pagesData !== null && pagesData !== undefined){
             for ( var i = 0; i < pagesData.length; i++ ) {
                if(pagesData[i].page == this.props.location.state.page){
                    this.setState({
                        page_data: pagesData[i]
                    });
                }
             }
        } else {
            setTimeout(function(){
                that._showData();
            }, 250);
        }
 
        var catData = JSON.parse(localStorage.getItem('catData'));
        if(catData !== null && catData !== undefined){
             this.setState({
                 cat_data: catData
             });
        } else {
            setTimeout(function(){
                that._showData();
            }, 250);
        }

        var codes = JSON.parse(localStorage.getItem('codes'));
        if(codes !== null && codes !== undefined){
             this.setState({
                codes: codes
             });
        } else {
            setTimeout(function(){
                that._showData();
            }, 250);
        }
    }

    render() {
        var generalData = this.state.general_data;
        var pageData = this.state.page_data;

        return (
            <div className="wrapper">
                {(generalData !== null && generalData !== undefined && pageData !== null && pageData !== undefined) ?
                <div>
                    <Header data={generalData} ref={this.topRef}/>
                    {(pageData.cover_image !== null && pageData.cover_image !== undefined) &&
                    <div className="coverImage p-0" style={{ backgroundImage: `url(${pageData.cover_image.data.full_url})` }}>
                        <div className="slogan">
                            <ScrollTo>
                                {({ scroll }) => (
                                <a onClick={() => scroll({ ref: this.contentRef, x: 20, y: 500, smooth: true})}>
                                    <img src={ArrowDownWhite} alt="Arrow down" className="arrowDownIcon"/>
                                </a>
                                )}
                             </ScrollTo>
                        </div>
                    </div>
                    }
                    <Container ref={this.contentRef} className={(pageData.cover_image !== null && pageData.cover_image !== undefined) ? "" : "content-wrapper"}>
                        <Row className="d-flex justify-content-center mb-4">
                            <Col xs={12} lg={10} className="pt-4 pt-lg-5 text-lg-left">
                                <h1 className="pb-4 text-uppercase">{pageData.title}</h1>
                                {pageData.text !== null &&
                                    <span className="p">{parse(pageData.text)}</span>
                                }
                            </Col>
                        </Row>
                    </Container>
                    <Footer data={generalData}/>
                </div>
                :
                <div className={"loader d-flex align-items-center justify-content-center"}>
                    <Loader type="Grid" color="#000" height="40" width="40" />
                </div>
                }
            </div>
        );
    }
}

export default Onboarding;