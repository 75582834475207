import React from 'react';
import '../style/App.scss';

class Inputfield extends React.Component {
    render() {
      return (
        <div>
            <div className={this.props.error ? "input-wrapper error" : "input-wrapper"}>
                <input type={this.props.secure ? "password" : this.props.email ? "email" : "text"} value={this.props.value} className="input" placeholder=" " onBlur={this.props.onBlur} onChange={this.props.onChange} autofill="" maxLength={this.props.maxLength ? this.props.maxLength : null}/>
                <label>{this.props.label}</label>
            </div>
            {this.props.error &&
                <span className="p-small text-left error d-block mb-3">{this.props.errorMessage}</span>
            }
        </div>
      );
    }
}
export default Inputfield;