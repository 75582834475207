import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import '../style/App.scss';
import FontAwesome from 'react-fontawesome';

class Header extends React.Component {
    render() {
      return (
          <div>
            <header>
                <Container fluid className="p-0">
                    <Row className="d-flex align-items-center justify-content-between header">
                        <Col className="pl-2">
                            <Link to={{pathname: '/', state: {}}}>
                                <FontAwesome
                                    className='menuIcon'
                                    name='bars'
                                />
                            </Link>
                        </Col>
                        <Col className="d-none d-lg-block text-center">
                            <Link to={{pathname: '/', state: {}}}>
                                {(this.props.data.logo_big !== undefined && this.props.data.logo_big !== null) &&
                                    <img src={this.props.data.logo_big.data['full_url']} alt="Logo" className="logo desktop"/>
                                }
                            </Link>
                        </Col>
                        <Col className="d-block d-lg-none text-center">
                            <Link to={{pathname: '/', state: {}}}>
                                {(this.props.data.logo_small !== undefined && this.props.data.logo_small !== null) &&
                                    <img src={this.props.data.logo_small.data['full_url']} alt="Logo" className="logo mobile"/>
                                }
                            </Link>
                        </Col>
                        <Col className="text-right pr-2">
                            <Link to={{pathname: '/login', state: {}}}>
                                <FontAwesome
                                    className='accountIcon'
                                    name='user-secret'
                                />
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </header>
            <div className="header-bg" style={{background: this.props.data.header_bg_color}}></div>
        </div>
      );
    }
}
export default Header;