import React from 'react';
import '../style/App.scss';
import InputField from './inputfield';
import Button from './button';
import Validators from '../helpers/functions';
import Loader from 'react-loader-spinner';
import '../components/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const validator = new Validators();
var parse = require('html-react-parser');

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailError: false,
            emailErrorMessage: '',
            personalcode: '',
            personalcodeError: false,
            personalcodeMessage: '',
            fullname: '',
            fullnameError: false,
            fullnameErrorMessage: '',
            success: false,
            success_questionaire: false,
            selectedArray: [],
            codeValid: false,
            generalError: '',
            inProgress: false,
            filledFormId: 0
        };
    }

    _onBlur(field){
        if(field == 'email'){
            if(!validator.validateEmail(this.state.email)){
                this.setState({
                    emailError: false
                });
            } else {
                this.setState({
                    emailError: true,
                    emailErrorMessage: this.props.data.form_invalid_email
                });
                
            }
        } else if(field == 'fullname'){
            if(this.state.fullname !== ''){
                this.setState({
                    fullnameError: false
                });
            } else {
                this.setState({
                    fullnameError: true,
                    fullnameErrorMessage: this.props.data.form_field_is_required
                });
            }
        } else if(field == 'personalcode'){
            this._validateCode();
        }
    }

    _validateCode(){
        if(validator.validateCode(this.state.personalcode)){
            this.setState({
                personalcodeError: false,
                codeValid: true
            });
        // } if(validator.validateCode(this.state.personalcode) == 'used'){ 
        //     this.setState({
        //         personalcodeError: true,
        //         personalcodeErrorMessage: this.props.data.form_code_already_used,
        //         codeValid: false
        //     });
        } else {
            this.setState({
                personalcodeError: true,
                personalcodeErrorMessage: this.props.data.form_incorrect_code,
                codeValid: false
            });
        }
    }

    _onChange(event, field){
        var errorField = field + 'Error';

        this.setState({
            [field]: event.target.value,
            [errorField]: false
        })
    }

    _onSend(){
        let generalData = this.props.data;

        if(!validator.validateEmail(this.state.email) && this.state.fullname !== '' && this.state.codeValid){
            this.setState({
                inProgress: true
            });

            fetch('https://cors-anywhere.herokuapp.com/https://exclusivehotelbookings-cms.tecdam.io/public/exclusivehotelbookings/items/forms', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'X-Requested-With': 'XMLHttpRequest'
                },
                body: JSON.stringify({
                  full_name: this.state.fullname,
                  email: this.state.email,
                  from_page: 'Vip',
                  personal_code_used: this.state.personalcode
                })
              })
              .then(response => {
                if(response.status == 409) {
                //EMAIL ALREADY EXISTS
                this.setState({
                    generalError: generalData.form_already_exists
                }) 
                } else if(response.status == 200){
                    //BIG FAIL
                    this.setState({
                        generalError: ''
                    })
                } else{
                    //BIG FAIL
                    this.setState({
                        generalError: generalData.form_generic_error
                    })
                }
              return response.json();
            }).then(data => {
                this.setState({
                    inProgress: false
                });
                if(data && data.data && data.data.id) {
                    validator.sendEmail(this.state.email, 'Vip');
                    let id = data.data.id;
                    this.setState({
                        success: true,
                        filledFormId: id
                    });
                }
            });

        } else {
            if(this.state.fullname !== ''){
                this.setState({
                    fullnameError: false
                });
            } else {
                this.setState({
                    fullnameError: true,
                    fullnameErrorMessage: this.props.data.form_field_is_required
                });
            }
            if(!validator.validateEmail(this.state.email)){
                this.setState({
                    emailError: false
                });
            } else {
                this.setState({
                    emailError: true,
                    emailErrorMessage: this.props.data.form_invalid_email
                });
            }
            this._validateCode();
        }
    }

    _submitQuestions(){
        if(this.state.selectedArray.length > 0 && this.state.filledFormId !== 0){
            this.setState({
                inProgress: true
            });
            let names = [];
            for(let selected of this.state.selectedArray) {
                names.push(selected.name);
            }
            names = names.join();
            fetch('https://cors-anywhere.herokuapp.com/https://exclusivehotelbookings-cms.tecdam.io/public/exclusivehotelbookings/items/forms/' + this.state.filledFormId, {
                method: 'PATCH',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'X-Requested-With': 'XMLHttpRequest'
                },
                body: JSON.stringify({
                  travel_preferences: names
                })
              })
              .then(response => {
                this.setState({
                    inProgress: false,
                    success_questionaire: true
                });
            });
        }
    }

    _isSelected(currentId){
        for(let list of this.state.selectedArray) {
            if(list.id === currentId) {
                return true;
            }
        }
    }

    _addCat(selected){
        let selectedArray = [...this.state.selectedArray];
        let index = selectedArray.findIndex(el => el.id === selected.id);
        if(index == -1){
            selectedArray.push({
                id: selected.id,
                name: selected.name
            });
        } else {
            selectedArray.splice(index,1);
        }
        this.setState({ selectedArray });
    }

    render() {
        var that = this;

      return (
        !this.state.success ?
        <div>
            <span className="form-header d-block mb-4">{this.props.data.form_vip_header}</span>
            <InputField 
                label={this.props.data.form_personal_code}
                onBlur={() => this._onBlur('personalcode')}
                value={this.state.personalcode}
                onChange={(val) => this._onChange(val, 'personalcode')}
                error={this.state.personalcodeError}
                errorMessage={this.state.personalcodeErrorMessage}
                maxLength="19"
            />
            <InputField 
                label={this.props.data.form_full_name}
                onBlur={() => this._onBlur('fullname')}
                value={this.state.fullname}
                onChange={(val) => this._onChange(val, 'fullname')}
                error={this.state.fullnameError}
                errorMessage={this.state.fullnameErrorMessage}
            />
            <InputField 
                label={this.props.data.form_email} 
                onBlur={() => this._onBlur('email')}
                value={this.state.email}
                onChange={(val) => this._onChange(val, 'email')}
                error={this.state.emailError}
                errorMessage={this.state.emailErrorMessage}
                email
            />

            {this.state.generalError !== '' &&
                <span className="p-medium error d-block mt-4 mb-4">{this.state.generalError}</span>
            }

            <span className="p-medium d-block mt-4 mb-4"><i>{parse(this.props.data.form_conditions_text)}</i></span>

            {this.state.inProgress ?
                <Loader type="Oval" color="#000" height="40" width="40" />
            :
                <Button text={this.props.data.form_vip_activate_button} buttonBackgroundColor={this.props.data.button_background} buttonTextColor={this.props.data.button_text_color} buttonBorderColor={this.props.data.button_border_color} onClick={() => this._onSend()}/>
            }
            <span className="p-medium d-block mt-4 mb-4">{this.props.data.form_no_obligations_text}</span>
        </div>
        :
        <div>
            {!this.state.success_questionaire ?
            <div>
                <span className="form-header d-block mb-3">
                    {parse(this.props.data.form_vip_success_header)}
                </span>
                <span className="p d-block mb-5">
                    {parse(this.props.data.form_vip_success_text)}
                </span>
                {(this.props.categories !== null && this.props.categories.length > 0) &&
                <div>
                    <div className="d-flex flex-direction-row align-items-center justify-content-center mb-5">
                        {Object.keys(this.props.categories).map(function(item, i){
                            return (
                                <div key={that.props.categories[i].id} className={that._isSelected(that.props.categories[i].id) ? "cat-block selected d-flex flex-column align-items-center justify-content-center" : "cat-block d-flex flex-column align-items-center justify-content-center"} onClick={() => that._addCat(that.props.categories[i])}>
                                    <FontAwesomeIcon
                                        className='accountIcon'
                                        icon={['fa', that.props.categories[i].fontawesome_icon_name]}
                                        style={{fontSize: '45px', marginBottom: '25px'}}
                                    />
                                    <span>
                                        {that.props.categories[i].name}
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                    {this.state.inProgress ?
                        <Loader type="Oval" color="#000" height="40" width="40" />
                    :
                        <Button text={this.props.data.form_submit_button} buttonBackgroundColor={this.props.data.button_background} buttonTextColor={this.props.data.button_text_color} buttonBorderColor={this.props.data.button_border_color} onClick={() => this._submitQuestions()}/>
                    }
                </div>
                }
            </div>
            :
            <div>
                <span className="form-header d-block mb-3">
                    {parse(this.props.data.form_questionaire_success_header)}
                </span>
                <span className="p d-block mb-5">
                    {this.props.data.form_questionaire_success_text ? parse(this.props.data.form_questionaire_success_text) : ''}
                </span>
            </div>
            }
        </div>
      );
    }
}
export default Form;