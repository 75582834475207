import React from 'react';
import ReactDOM from 'react-dom';
import Router from './views/router';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import faStyles from 'font-awesome/css/font-awesome.css'; //needed to display FA icons, even if it shows as unused

setTimeout(ReactDOM.render(<React.StrictMode>
    <Router />
  </React.StrictMode>, document.getElementById('root')), 1500);

serviceWorker.unregister();
