import React from 'react';
import '../style/App.scss';
import InputField from './inputfield';
import Button from './button';
import Validators from '../helpers/functions';
import Loader from 'react-loader-spinner';

const validator = new Validators();
var parse = require('html-react-parser');

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailError: false,
            emailErrorMessage: '',
            fullname: '',
            fullnameError: false,
            fullnameErrorMessage: '',
            success: false,
            generalError: '',
            inProgress: false
        };
    }

    _validateEmail(){
        return true;
    }

    _onBlur(field){
        if(field == 'email'){
            if(!validator.validateEmail(this.state.email)){
                this.setState({
                    emailError: false
                });
            } else {
                this.setState({
                    emailError: true,
                    emailErrorMessage: this.props.data.form_invalid_email
                });
                
            }
        } else if(field == 'fullname'){
            if(this.state.fullname !== ''){
                this.setState({
                    fullnameError: false
                });
            } else {
                this.setState({
                    fullnameError: true,
                    fullnameErrorMessage: this.props.data.form_field_is_required
                });
            }
        }
    }

    _onChange(event, field){
        var errorField = field + 'Error';

        this.setState({
            [field]: event.target.value,
            [errorField]: false
        })
    }

    _onSend(){
        var generalData = this.props.data;
        if(!validator.validateEmail(this.state.email) && this.state.fullname !== ''){

            this.setState({
                inProgress: true
            });

            fetch('https://cors-anywhere.herokuapp.com/https://exclusivehotelbookings-cms.tecdam.io/public/exclusivehotelbookings/items/forms', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  full_name: this.state.fullname,
                  email: this.state.email,
                  from_page: 'Home'
                })
              })
              .then(response => {
                this.setState({
                    inProgress: false
                });

                if(response.status == 409) {
                    //EMAIL ALREADY EXISTS
                    this.setState({
                        generalError: generalData.form_already_exists
                    })
                } else if(response.status == 200) {
                    validator.sendEmail(this.state.email, 'Home');
                    this.setState({
                        success: true
                    });
                } else {
                    //BIG FAIL
                    this.setState({
                        generalError: generalData.form_generic_error
                    })
                }});
        } else {
            if(this.state.fullname !== ''){
                this.setState({
                    fullnameError: false
                });
            } else {
                this.setState({
                    fullnameError: true,
                    fullnameErrorMessage: this.props.data.form_field_is_required
                });
            }
            if(!validator.validateEmail(this.state.email)){
                this.setState({
                    emailError: false
                });
            } else {
                this.setState({
                    emailError: true,
                    emailErrorMessage: this.props.data.form_invalid_email
                });
            }
        }
    }

    render() {
      var that = this;

      return (
        !this.state.success ?
        <div>
            <span className="form-header d-block mb-4">{this.props.data.form_home_header}</span>
            {this.props.data.form_home_header_subtitle &&
                <span className="p-medium d-block mb-4">{this.props.data.form_home_header_subtitle}</span>
            }
            <InputField 
                label={this.props.data.form_full_name}
                onBlur={() => this._onBlur('fullname')}
                value={this.state.fullname}
                onChange={(val) => this._onChange(val, 'fullname')}
                error={this.state.fullnameError}
                errorMessage={this.state.fullnameErrorMessage}
            />
            <InputField 
                label={this.props.data.form_email} 
                onBlur={() => this._onBlur('email')}
                value={this.state.email}
                onChange={(val) => this._onChange(val, 'email')}
                error={this.state.emailError}
                errorMessage={this.state.emailErrorMessage}
            />

            {this.state.generalError !== '' &&
                <span className="p-medium error d-block mt-4 mb-4">{this.state.generalError}</span>
            }

            <span className="p-medium d-block mt-4 mb-4"><i>{parse(this.props.data.form_conditions_text)}</i></span>

            {this.state.inProgress ?
                <Loader type="Oval" color="#000" height="40" width="40" />
            :
                <Button text={this.props.data.form_activate_button} buttonBackgroundColor={this.props.data.button_background} buttonTextColor={this.props.data.button_text_color} buttonBorderColor={this.props.data.button_border_color} onClick={() => this._onSend()}/>
            }
           
            <span className="p-medium d-block mt-4 mb-4">{this.props.data.form_no_obligations_text}</span>
        </div>
        :
        <div>
            <span className="form-header d-block mb-3">
                {parse(this.props.data.form_home_success_header)}
            </span>
            <span className="p d-block mb-4">
                {this.props.data.form_home_success_text ? parse(this.props.data.form_home_success_text) : ''}
            </span>
        </div>
      );
    }
}
export default Form;