import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style/App.scss';

import Header from '../components/header';
import Footer from '../components/footer';
import { Link } from "react-router-dom";
import Form from '../components/form-home';
import Loader from 'react-loader-spinner';
import ArrowDownWhite from '../img/arrow-down.png';
import ArrowUpBlack from '../img/arrow-up.png';
import { ScrollTo } from "react-scroll-to";
import Validators from '../helpers/functions';
import DocumentMeta from 'react-document-meta';
const validator = new Validators();
var parse = require('html-react-parser');

const fadeDuration = 10

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            general_data: null,
            page_data: null,
        };
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        validator.getAllData();
        this._showData(); 
    }

    _showData(){
        var generalData = JSON.parse(localStorage.getItem('generalData'));
        var that = this;
        
        if(generalData !== null && generalData !== undefined){
            setTimeout(function(){
                that.setState({
                    general_data: generalData
                });
            }, 250);
        } else {
            setTimeout(function(){
                that._showData();
            }, 250);
        }
 
        var pagesData = JSON.parse(localStorage.getItem('pagesData'));
        if(pagesData !== null && pagesData !== undefined){
             for ( var i = 0; i < pagesData.length; i++ ) {
                 if(pagesData[i].page === 'Landingspage'){
                     this.setState({
                         page_data: pagesData[i]
                     });
                 }
             }
        } else {
            setTimeout(function(){
                that._showData();
            }, 250);
        }
    }

    render() {
        var generalData = this.state.general_data;
        var pageData = this.state.page_data;

        let meta = {};
        if(this.state.page_data) {
            meta = {
                title: pageData.title_meta,
                description: pageData.description_meta,
                meta: {
                    charset: 'utf-8',
                    name: {
                        keywords: pageData.keywords
                    }
                }
            };
        }

        return (
            <DocumentMeta {...meta}>
            <div className="wrapper">
                {(generalData !== null && pageData !== null) ?
                <div>
                    <Header data={generalData} ref={this.topRef}/>
                    {(pageData.cover_image !== null && pageData.cover_image !== undefined) &&
                    <div className="coverImage p-0" style={{ backgroundImage: `url(${pageData.cover_image.data.full_url})` }}>
                        <span className="subtitle">{pageData.invited_text}</span>
                        
                        <div className="slogan">
                            <span className="d-block">{pageData.activate_text}</span>
                            <ScrollTo>
                                {({ scroll }) => (
                                <a onClick={() => scroll({ ref: this.contentRef, x: 20, y: 500, smooth: true})}>
                                    <img src={ArrowDownWhite} alt="Arrow down" className="arrowDownIcon"/>
                                </a>
                                )}
                             </ScrollTo>
                        </div>
                    </div>
                    }
                    <Container ref={this.contentRef} className={(pageData.cover_image !== null && pageData.cover_image !== undefined) ? "" : "content-wrapper"}>
                        <Row>
                            <Col xs={12} lg={7} className="p-4 p-lg-5 text-center text-lg-left">
                                <div className="d-flex justify-content-between flex-column h-100">
                                    <div>
                                        <h1 className="pb-4">{pageData.title}</h1>
                                        <span className="p">{parse(pageData.text)}</span>
                                    </div>
                                    
                                    <div className="d-block mt-4">
                                        <ScrollTo>
                                            {({ scroll }) => (
                                            <a onClick={() => scroll({x: 0, y: 0, smooth: true})}>
                                                <img src={ArrowUpBlack} alt="Arrow up" className="arrowUpIcon"/>
                                            </a>
                                            )}
                                        </ScrollTo>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={5} className="mt-lg-5 mb-5">
                                <div className="border-form pl-4 pr-4 pb-4 pr-lg-5 pl-lg-5 pb-lg-5 text-center">
                                    <Form data={generalData}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Footer data={generalData}/>
                </div>
                :
                <div className={"loader d-flex align-items-center justify-content-center"}>
                    <Loader type="Grid" color="#000" height="40" width="40" />
                </div>
                }
            </div>
            </DocumentMeta>
        );
    }
}

export default Home;