import React from 'react';
import { Link } from "react-router-dom";
import '../style/App.scss';

class Button extends React.Component {
    render() {
      return (
        <Link onClick={this.props.onClick} to={{pathname: this.props.path, state: {}}} className="button d-flex align-items-center justify-content-center" style={{background: this.props.buttonBackgroundColor, color: this.props.buttonTextColor, border: '1px solid ' + this.props.buttonBorderColor}}>
            {this.props.text}
        </Link>
      );
    }
}
export default Button;