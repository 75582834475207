import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style/App.scss';
import FontAwesome from 'react-fontawesome';
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
var parse = require('html-react-parser');

class Footer extends React.Component {
    render() {
      return (
        <footer>
            <Container fluid>
                <Row className="d-flex align-items-center">
                    <Col xs={12} lg={4} className="text-center text-lg-left mb-3 mb-lg-0 order-3 order-lg-1">
                       <span className="d-block">{this.props.data.footer_copyright_text}</span>
                       <span className="p-small">
                            <Link to={{pathname: '/privacy-policy', state: { page: "Privacy Policy"}}}>{this.props.data.privacy_page_link_text}</Link> &
                            <Link to={{pathname: '/general-conditions', state: { page: "General conditions"}}}> {this.props.data.terms_page_link_text}</Link>
                           </span>
                    </Col>
                    <Col xs={12} lg={4} className="text-center mb-3 mb-lg-0 order-1 order-lg-2">
                        {(this.props.data.footer_instagram_url !== null && this.props.data.footer_instagram_url !== undefined && this.props.data.footer_instagram_url !== "") &&
                        <a href={this.props.data.footer_instagram_url} target="_blank" rel="noopener noreferrer">
                            <FontAwesome
                                className='instagramIcon'
                                name='instagram'
                            />
                        </a>
                        }
                    </Col>
                    <Col xs={12} lg={4} className="text-center text-lg-right order-2 order-lg-3 mb-3 mb-lg-0">
                        <span className="d-block">
                            <a href={"mailto:" + this.props.data.footer_contact_email}>{this.props.data.footer_contact_email}</a>
                        </span>
                        <a href={"tel:" + this.props.data.footer_contact_phonenumber}>{this.props.data.footer_contact_phonenumber}</a> <span className="p-small">{this.props.data.footer_opening_times}</span>
                    </Col>
                </Row>
            </Container>
            <CookieConsent
                location="top"
                buttonText={this.props.data.cookie_bar_button}
                cookieName="exclusive-consent"
                style={{ color: "#000", background: "#fff" }}
                buttonStyle={{ color: this.props.data.button_text_color, fontSize: "16px", background: this.props.data.button_background }}
                expires={150}
                debug={false}
                >
                {parse(this.props.data.cookie_bar_text)}
            </CookieConsent>
        </footer>
      );
    }
}
export default Footer;